<template>
  <base-material-card icon="mdi-clipboard-text" :title="$t('menu.referral.list')" class="px-5 py-3">
    <div class="mt-2">{{ $t('referral.viewTotal') }}: {{ meta.total }}</div>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="primary--text">No.</th>
          <th class="primary--text">
            {{ $t('referral.number') }}
          </th>
          <th class="primary--text">
            {{ $t('referral.referredEmail') }}
          </th>
          <th class="primary--text">
            {{ $t('referral.referredName') }}
          </th>
          <th class="primary--text">
            {{ $t('referral.referrerEmail') }}
          </th>
          <th class="primary--text">
            {{ $t('referral.referrerName') }}
          </th>
          <th class="primary--text">
            {{ $t('referral.createdAt') }}
          </th>
          <th class="primary--text">
            {{ $t('referral.product') }}
          </th>
          <th class="primary--text">
            {{ $t('referral.rewardGivenAt') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in items" :key="`referral-${index}`">
          <td>{{ index + 1 }}</td>
          <td>{{ item.referral_number }}</td>
          <td>{{ item.referred_email }}</td>
          <td>{{ item.referred_name }}</td>
          <td>{{ item.referrer_email }}</td>
          <td>{{ item.referrer_name }}</td>
          <td>
            {{ item.created_at ? format(new Date(item.created_at), 'dd MMMM yyyy HH:mm') : '' }}
          </td>
          <td>{{ item.order_product }}</td>
          <td>
            {{
              item.reward_given_at
                ? format(new Date(item.reward_given_at), 'dd MMMM yyyy HH:mm')
                : ''
            }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions'],
  components: {
    Pagination,
  },
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.referral.basePath,
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    changeFilter(val) {
      this.$emit('changeFilter', val);
    },
  },
};
</script>
